import React from "react";
import { Link, useHistory} from 'react-router-dom'
import { Button, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import firebase from "firebase";
// import { withFirebase } from "./Firebase";


import img from './ideas.svg'
const ReactMarkdown = require("react-markdown");

const useStyles = makeStyles((theme) => ({
  div: {
    paddingTop: 32,
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      paddingTop: 24,
    },
  },
  img: {
    height: 300,
    [theme.breakpoints.down("sm")]: {
      height: 200,
    },
  },
  buttonContainer: {
    margin: "auto",
    textAlign: 'center',
  },
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    borderRadius: 180,
    // height: 6,
    // width: 128,
    color: "white",
  },
  md: {
    textAlign: "center",
  },
}));


const input = `
# Store your insights in a single place
Drop is a place for you to store the insights you learn 
from reading books, listening to podcasts, or having those lightbulb 
moments throughout the day.

# Connect ideas later on
Drop lets you connect the dots between insights by linking notes to each other. 
You can add on to your notes as you find new info or change your perspective over time.
`;

 function Home(props) {
  const classes=useStyles();
  const history = useHistory();
  const signin = () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        history.push("/notes");

        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };
  return (
    <Container maxWidth="sm">
      <div className={classes.div}>
        <img className={classes.img} src={img} />
      </div>
      {}
      <ReactMarkdown className={classes.md} source={input} />
      <div className={classes.buttonContainer}>
        <Link to='/notes'>
          <Button
            // onClick={}
            className={classes.button}
            color="primary"
            variant="contained"
          >
            Get Started
          </Button>
        </Link>
      </div>
    </Container>
  );
}

export default Home;