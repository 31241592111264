import React from "react";

import { Button, Container, Input, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from 'react-router-dom'
import img from "./ideas.svg";
import { compose } from "redux";

import {withFirebase} from "./Firebase";
import { withAuthorization } from "./Session";
import styled from 'styled-components'
const ReactMarkdown = require("react-markdown");

const useStyles = makeStyles((theme) => ({
  div: {
    paddingTop: 32,
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      paddingTop: 24,
    },
  },
  img: {
    height: 300,
    [theme.breakpoints.down("sm")]: {
      height: 200,
    },
  },
  buttonContainer: {
    margin: "auto",
    textAlign: "center",
  },
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    borderRadius: 180,
    // height: 6,
    // width: 128,
    color: "white",
  },
  md: {
    textAlign: "center",
  },
  title: {
    fontSize: 32,
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderRadius: "12px",
    background: "#efefef",
    boxShadow: " 5px 5px 10px #cbcbcb, -5px -5px 10px #ffffff",
  },
  notetitle: {
    fontSize: 24,
    fontWeight: 800,
  },
  addHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const Search = styled(Input)`
  border: none;
  margin-bottom: 18px;
  width: 100%;
  padding-left: 12px;
  border-radius: 50px;
  background: #efefef;
  box-shadow: inset 4px 4px 7px #cbcbcb, inset -4px -4px 7px #ffffff;
`;
            


const Note = ({classes, data}) => (
    <Paper className={classes.paper}>
        {/* <Typography className={props.classes.notetitle}>{props.title}</Typography>
        <br /> */}
    <Typography variant="body1">{data.created_at.toDate().toLocaleString()}</Typography>
        { data.source == 'book' && 
      <Typography variant="body1">Sourced from book: {data.bookTitle}</Typography>
        }
        {data.source == 'article' &&
      <Typography variant="body1">Sourced from  <a href={data.url}>Link</a></Typography>
        }
        {data.source == 'event' &&
      <Typography variant="body1"> Sourced from an event: <br/>{data.event}</Typography>
        }
        <Typography variant="body1">
          <ReactMarkdown source={data.description}/>
        </Typography>
      </Paper>
);

const fetchNotes = async (firebase) => {
  let ret = []
  await firebase.firestore.collection('notes').get()
    .then(docs => docs.forEach(i => {
      console.log(i.data());
      ret.push(i.data())
    }))
  .then(() =>ret)
}

const Notes = (props) => {
  const classes = useStyles();

  const [user, setUser] = React.useState([])
  const [notes, setNotes] = React.useState([])
  const [search, setSearch] = React.useState('')

  React.useEffect( () =>{
      let ret = [];
      props.firebase.auth.onAuthStateChanged(async (user) => {
        if (user) {
          console.log(user)
          await props.firebase.firestore
            .collection("notes")
            .where("uid", "==", user.uid)
            .get()
            .then((docs) =>
              docs.forEach((i) => {
                // console.log(i.data());
                ret.push({ id: i.id, data: i.data() });
              })
            );
          ret = ret.sort((a,b) => b.data.created_at - a.data.created_at)
          setNotes(ret)
        }

          // this.setState({ user });
        })
      // });
      // const load = async () => {
      //   console.log(props.firebase.auth);
      //   await props.firebase.firestore
      //   .collection("notes")
      //   .get()
      //   .then((docs) =>
      //     docs.forEach((i) => {
      //       console.log(i.data());
      //       ret.push({id: i.id, data: i.data()});
      //     })
      //   );
      //   setNotes(ret)}
      //   load()
    }, [props.firebase]);
  return (
    <Container maxWidth="md">
      <div className={classes.addHeader}>
        <Typography align="left" className={classes.title} variant="h1">
          My Notes
        </Typography>
        <Link to="/add">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
          >
            Add a new note
          </Button>
        </Link>
      </div>
      <br />
      <br />
      <Search disableUnderline placeholder="search for keywords" className={classes.search} onChange={(e)=>setSearch(e.target.value)}>Search</Search>
      {notes &&
        notes.map((i) => (
          i.data.description.indexOf(search)>-1 &&
          <Note
            key={i.id}
            data={i.data}
            paper={classes.paper}
            classes={classes}
          ></Note>
        ))}
    </Container>
  );

  
}

const condition = (authUser) =>authUser !== null;

export default compose(
  withAuthorization(condition)
, withFirebase)(Notes);
