import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { v1 } from 'uuid'

const config = {
  apiKey: "AIzaSyD86SY4i5INqjiNIyp_hdnYLi3plun0KiQ",
  authDomain: "knowledge-drop-2ea4c.firebaseapp.com",
  databaseURL: "https://knowledge-drop-2ea4c.firebaseio.com",
  projectId: "knowledge-drop-2ea4c",
  storageBucket: "knowledge-drop-2ea4c.appspot.com",
  messagingSenderId: "180323663652",
  appId: "1:180323663652:web:a941599b634055e0b7fdb1",
  measurementId: "G-L8EMJT3LCE",
};
// Initialize Firebase


class Firebase {
  constructor() {
    app.initializeApp(config);
    app.analytics();

    this.auth = app.auth();
    this.firestore = app.firestore();
  }

  /**
   *
   *  *** AUTH API ***
   *
   * /

  /**
   * Creates a new user in Firebase
   * @param  {} email
   * @param  {} password
   */
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  addNote = (note) => {
    this.firestore.collection("notes").doc(v1()).set(note, { merge: true });
  };

  fetchNotes = async () => {
      let ret = []
      // console.log(this.auth.currentUser)
      await this.firestore
          .collection("notes")
          .get()
          .then((docs) =>
            docs.forEach((i) => {
              console.log(i.data());
              ret.push({id: i.id, data: i.data()});
            })
          )
      console.log(ret)
      return ret

  };
}
export default Firebase;

