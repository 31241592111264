import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Header from './components/Header.js'
import Home from './components/Home.js'
import AddNote from './components/AddNote.js'
import Notes from './components/Notes.js';
import withAuthentication  from "./components/Session/withAuthentication.js";

function App() {
  return (
    <Router>
      <Header />

      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/add">
          <AddNote />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/notes">
          <Notes />
        </Route>
        <Route path="/">
          <Home />
        </Route>{" "}
      </Switch>
    </Router>
  );
}



function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

export default withAuthentication(App);
