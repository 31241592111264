import React from "react";

import { Button, Container, FormControl, MenuItem, Input,InputLabel, Grid, Typography, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ChipInput from "material-ui-chip-input";
import { withFirebase } from './Firebase';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  div: {
    paddingTop: 32,
    margin: "auto",
    maxWidth: '80%',
    // textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      paddingTop: 24,
    },
  },
  img: {
    height: 300,
    [theme.breakpoints.down("sm")]: {
      height: 200,
    },
  },
  buttonContainer: {
    // textAlign: "center",
  },
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    borderRadius: 180,
    color: "white",
  },
  cancelButton: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    borderRadius: 180,
  },
  title: {
    fontSize: 32,
  },
  label: {
    fontSize: 24,
    paddingBottom: theme.spacing(2),
  },
  item: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  input: {
    width: "100%",
  },
  formControl: {
    width: 300,
    display: "block",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}));


const FormItem = ((props) => (
    <div className={props.classes.item}>
          <Typography align="left" className={props.classes.label} variant="h2">
            {props.label}
          </Typography>
          <Input multiline align="left" value={props.title} className={props.classes.input} onChange={props.onChange} />
        </div>
))

const FormChip = (props) => (
  <div className={props.classes.item}>
    <Typography align="left" className={props.classes.label} variant="h2">
      {props.label}
    </Typography>
    <ChipInput
      style={{width: '100%'}}
      value={props.chips}
      onAdd={props.onAdd}
      onDelete={props.onDelete}
      // onDelete={(chip, index) => handleDeleteChip(chip, index)}
    />
  </div>
);


function Home({firebase}) {
  const  history  = useHistory();
  // console.log(props)
  // console.log(firebase.fireStore)
  const classes = useStyles();
  // const [title, setTitle] = React.useState("");
  const [description , setDescription ] = React.useState('')
  const [chips, setChips] = React.useState(['chips']);
  const [source, setSource]  = React.useState('book');
  const [bookTitle, setBookTitle] = React.useState('');
  const [bookAuthor, setbookAuthor] = React.useState('');
  const [url, setUrl] = React.useState('')
  const [event, setEvent] = React.useState('')
  

  // const handleTitle = (e) => setTitle(e.target.value);
  const handleDescription = (e) => setDescription(e.target.value);
  const handleAddChip = (chip) => {
    let newChips = [...chips,chip]
    // console.log(newChips)
    setChips(newChips)
  }
  const onSave = () => {
    // console.log(firebase.auth)
    firebase.addNote({ 
      // title, 
      description, 
      chips, 
      source,
      bookTitle,
      bookAuthor,
      url,
      event,
      uid: firebase.auth.currentUser.uid,
      created_at: new Date()
     });
     history.push('/notes')
  }
  const handleDeleteChip =(chip,index) => setChips(chips.filter(i => i!==chip))
  return (
    <Container maxWidth="sm">
      <div className={classes.buttonContainer}>
        <Typography align="left" className={classes.title} variant="h1">
          Add a note
        </Typography>
        <br />
        <br />
        {/* <FormItem
          classes={classes}
          // title={title}
          label="Note Title"
          // onChange={handleTitle}
        ></FormItem> */}
        <FormItem
          classes={classes}
          title={description}
          label="Note ( can use markdown here )"
          onChange={handleDescription}
        ></FormItem>
        <FormChip
          classes={classes}
          chips={chips}
          label="Tags"
          onAdd={(chip) => handleAddChip(chip)}
          onDelete={(chip, index) => handleDeleteChip(chip, index)}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Source</InputLabel>
          <Select
            style={{ width: 300 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={source}
            onChange={(e)=>setSource(e.target.value)}
          >
            <MenuItem value="book">Book</MenuItem>
            <MenuItem value="article">Article</MenuItem>
            <MenuItem value="event">Personal</MenuItem>
            {/* <MenuItem value="">Other</MenuItem> */}
          </Select>
        </FormControl>
        { source == 'book' &&
        <div>
          <FormItem
            classes={classes}
            title={bookTitle}
            label="Book title"
            onChange={(e)=>setBookTitle(e.target.value)}
          />
          <FormItem
          classes={classes}
          title={bookAuthor}
          label="Book author"
          onChange={(e) => setBookTitle(e.target.value)}
          />
        </div>
        }
        { source == 'article' && 
          <FormItem
            classes={classes}
            title={url}
            label="Article url"
            onChange={(e) => setUrl(e.target.value)}
          />}
        {source == 'event' &&
          <FormItem
            classes={classes}
            title={event}
            label="What happened that led you to write this note"
            onChange={(e) => setEvent(e.target.value)}
          />}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {  description && source ?
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={onSave}
          >
                        Save Note
          </Button>

            :
           <Button
            className={classes.button}
            color="primary"
            disabled
            variant="contained"
            onClick={onSave}
          >
            Save Note
          </Button>
          }
          <Button
            className={classes.cancelButton}
            color="primary"
            variant="outlined"
            onClick={()=>history.push('/notes')}
          >
            Cancel
          </Button>{" "}
        </div>
      </div>
    </Container>
  );
}

export default withFirebase(Home)