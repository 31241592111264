import React, { useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthorization = (condition) => (Component) => {
  const WithAuthorizationBase = (props) => {
    useEffect(() => {
      props.firebase.auth.onAuthStateChanged((auth) => {
        if (!condition(auth)) {
          props.history.push('/');
        }
      });
    });
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (condition(authUser)
          ? <Component props={props} /> : null)}
      </AuthUserContext.Consumer>
    );
  };
  return compose(
    withFirebase,
    withRouter,
  )(WithAuthorizationBase);
};

export default withAuthorization;
