import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Firebase, { FirebaseContext } from "./components/Firebase";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#13B797",
    },
    typography: {
      fontFamily: [
        'Nunito-Sans',
        'Roboto',
        'Helvetica Neue',
        'Arial'
      ]
    }
  },
});
// console.log(theme)

ReactDOM.render(
  // <React.StrictMode>
        <FirebaseContext.Provider value={new Firebase()}>

    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </FirebaseContext.Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
