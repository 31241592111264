import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import firebase from 'firebase'
import {withFirebase} from './Firebase'
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
    display: "flex",
    // justlign: "center",
  },
  grid: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    // textAlign: "center",
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    // color: "white",
  },
  title: {
    // paddingLeft: theme.spacing(8),
    float: "left",
    flexGrow: 1,
    fontSize: 42,
    fontWeight: 600,
    // color: "white",
  },
  login: {
    // paddingRight: theme.spacing(8),
    // flex: 1,
    display: "inline",
    fontSize: 18,
    // paddingTop: 28,
    float: 'right',

    // color: 'white'
  },
  signin: {
    flex: 1,
    float: "right",
  },
}));

 function Header(props) {
  const classes = useStyles();
  // console.log(props.firebase.auth.GoogleAuthProvider())
  const [user, setUser] = React.useState([]);
  

  React.useEffect(() => {
    const unlisten = props.firebase.auth.onAuthStateChanged((authUser) => {
      // console.log(authUser)
      authUser ? setUser(authUser) : setUser(null);
    });
    return () => {
      unlisten();
    };
  });

  const history= useHistory();
  const signout = () => firebase.auth().signOut()

  const signin = () => {
      var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        history.push("/notes");

        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });

  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Container maxWidth="md" className={classes.grid}>
          <Link style={{color: 'black', textDecoration: 'none'}} to="/">
            <Typography variant="h6" className={classes.title}>
              Drop
            </Typography>
          </Link>
          <Button variant="outlined">
            {user ? (
              <Typography
                onClick={signout}
                className={classes.login}
                color="inherit"
              >
                Logout
              </Typography>
            ) : (
              <Typography
                onClick={signin}
                className={classes.login}
                color="inherit"
              >
                Login
              </Typography>
            )}
          </Button>
        </Container>
      </div>
    </div>
  );
}

export default withFirebase(Header)